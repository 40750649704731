import HTMLFlipBook from "react-pageflip";
import { Document, Page as PagePdf, pdfjs } from 'react-pdf'
import React, { useEffect, useRef, useState } from "react";
import Door from "../../assets/ic_door.png"
import Kitchen from "../../assets/ic_kitchen.png"
import Carpentry from "../../assets/ic_carpentry.png"
import white_logo from "../../assets/logo_white.png"
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Fade, Reveal } from "react-awesome-reveal";
import "./styles.css";
import { keyframes } from "@emotion/react";
import { Box, Button, Stack, Typography, IconButton } from "@mui/material";
import { getDownloadURL } from "firebase/storage";
import { storage, ref } from "../../firebase";
import { trackButtonClick } from "../../utils/tracker";

const Page = React.forwardRef((props, ref) => {
    return (
        <div style={{ backgroundColor: 'beige', overflow: 'hidden' }} ref={ref}>
            <p>{props.children}</p>
        </div>
    );
});

function MyAlbum(props) {

    const [numPages, setNumPages] = useState([null]);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [isLoadingFile, setIsLoadingFile] = useState(true)
    const [pdfSize, setPdfSize] = useState({ height: 0, width: 0 })
    const [bottomMenu, setBottomMenu] = useState([
        {
            id: '1',
            label: 'Doors',
            pdf: {
                id: 1,
                file: null,
                conatctUsType: 'doors',
                conatctUsLink: '/contactus',
                type: "DoorsPDF",
                name: "PDF Name"
            },
            icon: Door
        },
        {
            id: '2',
            label: 'Kitchen',
            pdf: {
                id: 2,
                file: null,
                conatctUsType: 'kitchen',
                conatctUsLink: '/contactuskitchen',
                type: "KitchenPDF",
                name: "PDF Name"
            },
            icon: Kitchen
        },
        {
            id: '3',
            label: 'Carpentry',
            pdf: {
                id: 3,
                file: null,
                conatctUsType: 'carpentry',
                conatctUsLink: '/contactuscarpentry',
                type: "CarpantryPDF",
                name: "PDF Name"
            },
            icon: Carpentry
        },
    ])
    const [selectedPDF, setSelectedPDF] = useState(null)


    const book = useRef();

    const customAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, -30px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;


    function onDocumentLoadSuccess(pdf) {
        pdf.getPage(1).then(function (page) {
            const viewport = page.getViewport({ scale: 1 });
            console.log({ width: viewport.width, height: viewport.height });
            setPdfSize({ width: viewport.width, height: viewport.height })
            setNumPages(pdf.numPages);
        });
    }

    // Function to get download URLs for multiple files
    const getDownloadURLs = async (paths) => {
        try {
            const urlPromises = paths.map(async (path) => {
                const fileRef = ref(storage, path.url);
                const url = await getDownloadURL(fileRef);
                return { type: path.type, url };
            });

            const urls = await Promise.all(urlPromises);


            // Convert array to object
            const urlsObject = urls.reduce((acc, { type, url }) => {
                acc[type] = url;
                return acc;
            }, {});
            return urlsObject;
        } catch (error) {
            console.error('Error getting download URLs:', error);
        }
    };

    useEffect(() => {
        const updateScale = () => {
            const windowWidth = window.innerWidth - 50;
            const windowHeight = window.innerHeight - 200;
            const pageHeight = pdfSize.height;
            const pageWidth = pdfSize.width;
            const scaleHeight = windowHeight / pageHeight;
            const scaleWidth = windowWidth / pageWidth;
            const scale = Math.min(scaleHeight, scaleWidth)
            setPdfSize({ width: parseInt(pdfSize.width * scale), height: parseInt(pdfSize.height * scale) })
            setScale(scale);
            setIsLoadingFile(false)
        };

        updateScale()

        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
    }, [numPages, selectedPDF]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);



    useEffect(async () => {
        const filePaths = [
            { type: 'doors', url: 'files/doors.pdf' },
            { type: 'kitchen', url: 'files/kitchen.pdf' },
            { type: 'carpentry', url: 'files/carpentry.pdf' }
        ];
        const urls = await getDownloadURLs(filePaths)

        const tempBottomMenu = [...bottomMenu]

        setBottomMenu(tempBottomMenu.map(item => {
            const tempPdf = { ...item.pdf }
            tempPdf.file = urls[item.pdf.conatctUsType]
            return {
                ...item, pdf: tempPdf
            }
        }))
    }, [])

    useEffect(() => {
        if (bottomMenu[props.categoryId ? props.categoryId : 0].pdf) {
            setSelectedPDF(bottomMenu[props.categoryId ? props.categoryId : 0].pdf)
        }

    }, [bottomMenu])





    return (
        <section id="about">
            <Fade duration={1000}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100dvh',
                    // maxHeight: '-webkit-fill-available'
                }} >
                    {selectedPDF != null && selectedPDF.file != null && <Document file={selectedPDF.file} onLoadSuccess={onDocumentLoadSuccess}
                        loading={() => <div style={{ width: '100%', height: '100%' }}>
                            <Reveal keyframes={customAnimation}
                                duration={200}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <img style={{ height: 200, width: 200 }} src={white_logo} alt='Logo' />
                                <h2 style={{ fontSize: 20, color: 'white', font: " 'opensans-bold', sans-serif", fontWeight: 'lighter', marginBottom: 30 }}>Loading {selectedPDF.name}...</h2>
                            </Reveal>
                        </div>}
                        onLoad={(e) => console.log(e)}>
                        {!isLoadingFile && pageNumber && pdfSize.width && <HTMLFlipBook
                            mobileScrollSupport={true}
                            startPage={0}
                            width={pdfSize.width}
                            height={pdfSize.height}
                            minWidth={250}
                            maxWidth={pdfSize.width}
                            minHeight={350}
                            maxHeight={pdfSize.height}
                            flippingTime={1000}
                            style={{ margin: "0 auto" }}
                            maxShadowOpacity={0.5}
                            className="album-web"
                            ref={book}
                            onFlip={(e) => setPageNumber(e.data + 1)}
                        >
                            {[...new Array(numPages).keys()].map(pageNo =>
                                <Page number={pageNo + 1} key={pageNo + ''} >
                                    <PagePdf pageNumber={pageNo + 1} scale={scale} loading={() => <div style={{ width: '100%', height: '100%' }}>
                                        <Reveal keyframes={customAnimation}
                                            duration={200}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                            <img style={{ height: 200, width: 200 }} src={white_logo} alt='Logo' />
                                            <h2 style={{ fontSize: 20, color: 'white', font: " 'opensans-bold', sans-serif", fontWeight: 'lighter', marginBottom: 30 }}>Loading {selectedPDF.name}...</h2>
                                        </Reveal>
                                    </div>} />
                                </Page>)}
                        </HTMLFlipBook>}
                    </Document>}


                </div>
            </Fade>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                position: 'absolute',
                bottom: 0,
                width: '100%'

            }}>

                {bottomMenu.map((item) => <Button
                    onClick={() => {
                        trackButtonClick(`View ${item.label}`)
                        if (selectedPDF.id != item.pdf.id) {
                            if (selectedPDF.type != item.pdf.type) {
                                setSelectedPDF(item.pdf)
                            } else {
                                book.current.pageFlip().turnToPage(0)
                            }
                        }
                    }}
                    key={item.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <img src={item.icon} style={{ height: 35, width: 35 }} />
                    <Typography style={{ fontSize: 12, color: 'white', font: " 'opensans-bold', sans-serif", marginBottom: 5, marginTop: 5 }}>{item.label}</Typography>
                </Button>)}

            </div>
            <Stack direction='column' style={{
                position: 'absolute',
                width: '100%',
                top: 10,
                display: 'flex',
                alignItems: 'center',

            }}>
                <h2 style={{ fontSize: 20, color: 'white', font: " 'opensans-bold', sans-serif", marginBottom: 5 }}>Ask Question?</h2>

                {selectedPDF != null && <Button
                    variant="contained"
                    startIcon={<SupportAgentIcon style={{
                        height: 25,
                        width: 25,
                        color: 'black'
                    }} />}
                    href={`${selectedPDF.conatctUsLink}`}
                    style={{
                        paddingRight: 10,
                        paddingLeft: 10,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 5,
                        backgroundColor: "white"

                    }}>

                    <Typography style={{
                        color: 'black',
                        textAlign: 'center',
                        fontSize: 18,
                    }}>Chat with Us</Typography>
                </Button>}

            </Stack>


            <Stack direction='row' style={{
                position: 'absolute',
                width: '100%',
                top: '50%',
                display: 'flex',
                alignItems: 'center',

            }}>
                <Box >
                    <IconButton
                        onClick={() => {
                            book.current.pageFlip().flipPrev()
                        }}>
                        <ArrowBackIosIcon style={{
                            color: 'white', height: 30,
                            width: 30,
                            marginLeft: 10
                        }} />
                    </IconButton>
                </Box>
                <Box sx={{ flex: 1 }} />
                <Box >
                    <IconButton
                        onClick={() => {
                            book.current.pageFlip().flipNext()
                        }}>
                        <ArrowBackIosIcon style={{
                            rotate: '180deg',
                            color: 'white', height: 30,
                            width: 30,
                            marginRIght: 10
                        }} />
                    </IconButton>
                </Box>



            </Stack>

        </section>
    );
}

export default MyAlbum;