import React, { useEffect, useState } from "react";
import { Fade, Reveal } from "react-awesome-reveal";
import white_logo from "../assets/logo_white.png"
import { keyframes } from "@emotion/react";
import Brochures from "./Brochures/Brochures";
import { Button, IconButton, Stack } from "@mui/material";
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import icon from "../assets/ic_door.png"
import iconK from "../assets/ic_kitchen.png"
import iconC from "../assets/ic_carpentry.png"
import Background from '../assets/background_video.mp4'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { trackButtonClick } from "../utils/tracker";

export default function Header(props) {
  const [showCatalogue, setShowCatalogue] = useState(false)
  const [stopAnimation, setStopAnimation] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [catalogueClicked, setCatalogueClicked] = useState(false)
  const [categoryId, setCategoryId] = useState(1)


  useEffect(() => {

    let tracker = null;

    if (catalogueClicked) {
      setStopAnimation(true)
      tracker = setTimeout(() => {
        setShowCatalogue(true)
      }, 1200);
    }


    return () => {
      if (tracker) {
        clearTimeout(tracker)
      }
    }

  }, [catalogueClicked])

  const customAnimation = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, -${window.innerHeight}px, 0);
  }
`;

  // let config = {
  //   num: [1, 2],
  //   rps: 0.8,
  //   radius: [5, 40],
  //   life: [1.5, 10],
  //   v: [2, 3],
  //   tha: [-50, 50],
  //   alpha: [0.8, 0],
  //   scale: [0.1, 0.6],
  //   body: icon,
  //   position: "all",
  //   //color: ["random", "#ff0000"],
  //   cross: "dead",
  //   random: 10
  // };
  // let config1 = {
  //   num: [1, 2],
  //   rps: 0.8,
  //   radius: [5, 40],
  //   life: [1.5, 10],
  //   v: [2, 3],
  //   tha: [-50, 50],
  //   alpha: [0.8, 0],
  //   scale: [0.1, 0.4],
  //   body: iconK,
  //   position: "all",
  //   //color: ["random", "#ff0000"],
  //   cross: "dead",
  //   random: 10
  // };
  // let config2 = {
  //   num: [1, 2],
  //   rps: 0.8,
  //   radius: [5, 40],
  //   life: [1.5, 10],
  //   v: [2, 3],
  //   tha: [-50, 50],
  //   alpha: [0.8, 0],
  //   scale: [0.1, 0.4],
  //   body: iconC,
  //   position: "all",
  //   //color: ["random", "#ff0000"],
  //   cross: "dead",
  //   random: 10
  // };



  return (
    <div id="home" style={{ height: window.innerHeight }} >
      {/* {!stopAnimation && <ParticlesBg type="custom" config={config} bg={true} />}
      {!stopAnimation && <ParticlesBg type="custom" config={config1} bg={true} />}
      {!stopAnimation && <ParticlesBg type="custom" config={config2} bg={true} />} */}

      {!stopAnimation && <video autoPlay={true} loop={true} muted={true} playsInline style={{
        position: 'absolute', height: "100%", width: "100%",
        objectFit: 'cover'
      }} >
        <source src={Background} type="video/mp4" />
      </video>}


      {showCatalogue ?
        <Brochures categoryId={categoryId} /> :
        <Reveal keyframes={catalogueClicked ? customAnimation : null}
          // delay={1800}
          // fraction={1}
          duration={1200}
          // direction='up'

          style={{
            height: "100%", width: "100%",
          }}>

          <div style={{
            height: "100%", width: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>

            <div style={{ position: 'absolute', height: "100%", width: "100%", background: 'rgba(0,0,0,0.65)' }} />

            {!showOptions && <Fade duration={600} cascade direction='up' damping={0.9}>
              <img style={{ height: 400, width: 400 }} src={white_logo} alt='Logo' />

              <h2
                style={{ fontSize: 35, color: 'white', font: " 'opensans-bold', sans-serif", marginBottom: 50 }}>Al-Qattan Home</h2>

              <p style={{
                marginBottom: 45, color: 'white', maxWidth: 550,
                textAlign: 'center',
                marginLeft: 30,
                marginRight: 30,
                fontSize: 15,
                font: "18px/1.9em 'librebaskerville-regular', serif"
              }}>Elevate Your Space! Kitchen Marvels, Cabinets, and Doors Tailored for You!</p>

              <Button variant="outlined"
                onClick={() => {
                  // setCatalogueClicked(true)
                  trackButtonClick(`View Catalogue`)
                  setShowOptions(true)
                }}
                style={{ color: 'white', borderColor: 'white', fontSize: 18 }}
                startIcon={<MenuBookOutlinedIcon style={{ height: 22, width: 22 }} />}>
                View Catalogue
              </Button>

            </Fade>}

            {showOptions && <Fade duration={400} cascade direction='up' damping={0.9} style={{
              alignSelf: 'stretch',
              paddingLeft: 50,
              paddingRight: 50
            }}>
              <div style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}>

                <img style={{ height: 150, width: 150, alignSelf: 'center', marginBottom: 70, }} src={white_logo} alt='Logo' />
              </div>

              <Button variant="outlined"
                onClick={() => {
                  trackButtonClick(`Doors Catalouge`)
                  setCatalogueClicked(true)
                  setCategoryId(0)
                }}
                fullWidth
                style={{
                  color: 'white', borderColor: 'white', fontSize: 24, marginBottom: 55,
                }}
                startIcon={<img src={icon} style={{ height: 35, width: 35 }} />}>
                Doors
              </Button>

              <Button variant="outlined"
                onClick={() => {
                  trackButtonClick(`Kitchen Catalouge`)
                  setCatalogueClicked(true)
                  setCategoryId(1)
                }}
                fullWidth
                style={{ color: 'white', borderColor: 'white', fontSize: 24, marginBottom: 55 }}
                startIcon={<img src={iconK} style={{ height: 35, width: 35 }} />}>
                Kitchen
              </Button>

              <Button variant="outlined"
                onClick={() => {
                  trackButtonClick(`Carpentry Catalouge`)
                  setCatalogueClicked(true)
                  setCategoryId(2)
                }}
                fullWidth
                style={{ color: 'white', borderColor: 'white', fontSize: 24, marginBottom: 55 }}
                startIcon={<img src={iconC} style={{ height: 35, width: 35 }} />}>
                Carpentry
              </Button>
            </Fade>}

          </div>

        </Reveal>}

      <Fade style={
        { position: 'absolute', top: 15, right: 15 }
      }>
        <IconButton
          href={"https://maps.app.goo.gl/hsRErgFHwyZwNx1h7"}
          aria-label="Location" style={{ color: 'rgba(255,255,255,0.8)' }}>
          <FmdGoodOutlinedIcon sx={{ height: 35, width: 35 }} />
        </IconButton>
      </Fade>

    </div>
  );

}
