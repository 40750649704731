import ReactPixel from "react-facebook-pixel";

const initFacebookPixel = (pixelId, options = { autoConfig: true, debug: false }) => {
    ReactPixel.init(pixelId, null, options);
    ReactPixel.pageView(); // Automatically track the first page view
};

const trackEvent = (eventName, data = {}) => {
    ReactPixel.track(eventName, data);
};

const trackButtonClick = (buttonName) => {
    ReactPixel.track(`ButtonClick | ${buttonName}`, {
        buttonName
    });
};

const trackPageView = () => {
    ReactPixel.pageView(); // Manually track a page view
};

export { initFacebookPixel, trackEvent, trackPageView, trackButtonClick };
