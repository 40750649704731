import React, { useEffect } from "react";
import "./App.css";
import AppRoutes from "./Routes/Routes";
import { initFacebookPixel, trackPageView } from "./utils/tracker";

const App = () => {

  useEffect(() => {
    const pixelId = "2019085451921560"; // Replace with your Facebook Pixel ID
    const options = {
      autoConfig: true, // Automatic configuration for Facebook Pixel
      debug: true, // Enable debug mode to log pixel events in the console
    };
    initFacebookPixel(pixelId, options);
    trackPageView()
  }, []);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
};

export default App;
